import React from "react"
import { Header } from "../components/Header"
import { useTranslation } from "react-i18next"
import { Button } from "../components/Button"
import { useHistory } from "react-router-dom"
import InstructionSection from "../components/InstructionSection"
import { colors } from "../variables"

export const InstructionPhotoPage = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={"container"}>
      <Header title={t("photo_guide.header")} />

      <div>
        <InstructionSection
          title={t("photo_guide.note")}
          content={t("photo_guide.note_message")}
        />
        <InstructionSection
          title={t("photo_guide.note_paper_printing")}
          content={t("photo_guide.note_paper_printing_message")}
        />
        <InstructionSection
          title={t("photo_guide.note_size")}
          content={t("photo_guide.note_size_message")}
        />

        <InstructionSection
          title={t("photo_guide.note_price_list")}
          content={""}
        />
        <table>
          <thead>
            <tr>
              <th>{t("photo_guide.photo_size")}</th>
              <th>{t("photo_guide.price_printing_1_photo")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>6x9</td>
              <td>2,000</td>
            </tr>
            <tr>
              <td>10x15</td>
              <td>3,000</td>
            </tr>
            <tr>
              <td>13x18</td>
              <td>4,000</td>
            </tr>
            <tr>
              <td>20x30</td>
              <td>30,000</td>
            </tr>
          </tbody>
        </table>
        <InstructionSection
          title={t("photo_guide.photo_resolution")}
          content={t("photo_guide.photo_resolution_message")}
        />
        <ol className={"list"}>
          <li>{t("photo_guide.dont_use_social_photo")}</li>
          <li>{t("photo_guide.dont_use_beauty_app")}</li>
        </ol>
        <InstructionSection
          title={t("photo_guide.size")}
          content={t("photo_guide.size_message")}
        />
        <InstructionSection
          title={t("photo_guide.amount")}
          content={t("photo_guide.amount_message")}
        />
        <InstructionSection
          title={t("photo_guide.upload_speed")}
          content={t("photo_guide.upload_speed_message")}
        />
        <InstructionSection
          title={t("photo_guide.color")}
          content={t("photo_guide.color_message")}
        />
      </div>

      <Button
        padding={16}
        title={t("button.understand_countinues")}
        onClick={() => {
          history.push({
            pathname: "process-photos"
          })
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          padding-bottom 16px
        }
        
        .list {
          margin 16px 16px 0 16px
          list-style decimal
          list-style-position inside
          
          li {
            line-height 20px
            
            &:first-child {
              margin-bottom 16px
            }
          }
        }
        
        table {
          margin 0 16px
        }
        
        th, td {
          border 1px solid ${colors.accent}
          padding 8px
        }
        
        th {
          font-weight bold
        }
      `}</style>
    </div>
  )
}
