import React from "react"
import { Header2 } from "../components/Header2"
import { useTranslation } from "react-i18next"
import { useLang } from "../hooks/useLang"

export const NotFoundPage = () => {
  const { t } = useTranslation()
  useLang()

  return (
    <div className={"container"}>
      <Header2 title={t("app.confirmation_page")} />

      <div className={"content"}>
        <span>404 Page not found</span>
      </div>

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          background #F4F5F7
          display flex
          justify-content center
          min-height 100vh
        }
        
        .content {
          display flex
          align-items flex-start
          background #FFF
          margin 97px 60px 60px
          //height 620px
          padding 60px
          width fit-content
          height fit-content
          font-size 20px
          
          @media only screen and (max-width 830px) {
            flex-direction column
            align-items center
            width 100%
            padding 16px
          }
        }
      `}</style>
    </div>
  )
}
