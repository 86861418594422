import React from "react"

export const LineLoading = () => {
  return (
    <div className="container">
      <div className="line" style={{ width: 100 }} />
      <div className="line" style={{ width: 130 }} />
      <div className="line" style={{ width: 120 }} />
      <div className="line" style={{ width: 120 }} />
      <div className="line" style={{ width: 100 }} />
      <div className="line" style={{ width: 150 }} />

      <div className="line" style={{ width: 130 }} />
      <div className="line" style={{ width: 150 }} />
      <div className="line" style={{ width: 155 }} />
      <div className="line" style={{ width: 30 }} />
      <div className="line" style={{ width: 70 }} />
      <div className="line" style={{ width: 40 }} />

      {/*language=Stylus*/}
      <style jsx>{`
        $base-color = #ddd
        $shine-color = #e8e8e8
        $animation-duration = 1.6s
        
        background-gradient() {
          background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px) 
          background-size: 600px
        }
        
        .container {
          display grid
          grid-template-columns: 200px 200px
          grid-template-rows repeat(6, 44px)
        }
          
        .line {
          float: left
          height: 20px
          margin-top: 12px
          
          background-gradient()
          animation: shine-lines $animation-duration infinite linear
        }
          
        .line ~ .line {
          background-color: #ddd
        }
         
        @keyframes shine-lines {
          0% {
            background-position: -100px
          }
          40%, 100% {
            background-position: 200px
          }
        }
      `}</style>
    </div>
  )
}
