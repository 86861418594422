import React from "react"
import { useTranslation } from "react-i18next"

export type HeaderProps = {
  reverse?: boolean
  title?: string
}

export const Header: React.FC<HeaderProps> = ({ reverse, title }) => {
  const { t } = useTranslation()

  const background = !reverse ? "#142246" : "#FFF"
  const color = !reverse ? "#FFF" : "#1A1A1A"

  return (
    <div className={"container"}>
      <h1 className={"title"}>{title ? title : t('app.name')}</h1>

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          display flex
          justify-content center
          align-items center
          height 60px
          background ${background}
          padding 20px
        }
        
        .title {
          //padding-top 34px
          font-family Roboto
          font-style normal
          font-weight 500
          font-size 16px
          line-height 24px
          text-align center
          color ${color}
        }
      `}</style>
    </div>
  )
}
