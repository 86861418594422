import React from "react"
import { AhamoveLogo } from "../assets"

export type Header2Props = {
  title: string
}

export const Header2: React.FC<Header2Props> = ({ title }) => {
  return (
    <div className={"header"}>
      <AhamoveLogo />
      <span className={"title"}>{title}</span>
      {/*language=Stylus*/}
      <style jsx>{`
        .header {
          height 69px
          box-shadow 0px 2px 6px rgba(0, 0, 0, 0.1)
          display flex
          align-items center
          padding 0 67px
          position fixed
          width 100%
          background #FFF
          
          @media only screen and (max-width 830px) {
            padding 0 16px
          }
        }
        
        .title {
          font-size 24px
          line-height 36px
          font-weight 500
          margin-left 8px
        } 
      `}</style>
    </div>
  )
}
