import { Modal } from "./Modal"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import photoSizes from "data/photo-sizes.json"
import { NumberInput } from "./NumberInput"
import { Button } from "./Button"
import { useTranslation } from "react-i18next"
import { ActionFixed } from "./ActionFixed"
import { Divider } from "../pages/CameraPage"
import { Photo } from "../interfaces"

export type EditModalProps = {
  data: Photo
  visible: boolean
  onUpdate(data: Photo): void
  onClose(): void
}

export const EditModal: React.FC<EditModalProps> = ({
  data,
  visible,
  onUpdate,
  onClose
}) => {
  const [value, setValue] = useState<any>(data.printingSize)
  const [numOfPhotos, setNumOfPhotos] = useState(data.totalImages)
  const { t } = useTranslation()

  useEffect(() => {
    setNumOfPhotos(data.totalImages)
    setValue(data.printingSize)
  }, [data])

  return (
    <Modal visible={visible}>
      <div className={"container"}>
        <span className={"label"}>{t('text.update_image_size')}</span>
        <div className={"selector-wrapper"}>
          <Select
            closeMenuOnSelect={true}
            isMulti={false}
            isSearchable={false}
            isClearable={true}
            value={value}
            getOptionLabel={v => v.size}
            getOptionValue={v => v.size}
            onChange={setValue}
            options={photoSizes}
          />
        </div>
        <span className={"label"}>{t('text.update_number_of_images')}</span>
        <div className={"selector-wrapper selector-wrapper--center"}>
          <NumberInput
            value={numOfPhotos}
            onChange={value => {
              setNumOfPhotos(value)
            }}
            min={1}
          />
        </div>

        <div className={"preview"}>
          <img src={data.thumbnailUrl} alt="" />
        </div>

        <ActionFixed>
          <Button
            background={"#F2F2F2"}
            color={"#142246"}
            title={t("button.close")}
            onClick={() => {
              setNumOfPhotos(data.totalImages)
              setValue(data.printingSize)

              onClose()
            }}
          />
          <Divider width={16}/>
          <Button
            padding={"16px 0 16px"}
            title={t("button.update")}
            onClick={() => {
              if (!value) {
                return
              }

              onUpdate({
                ...data,
                label: `${value.size} ${value.unit}`,
                totalImages: numOfPhotos,
                printingSize: value
              })
            }}
          />
        </ActionFixed>
      </div>

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          padding 16px 16px 0
          display flex
          flex-direction column
          width fill-available
          justify-content center
          background white
        }
        
        .selector-wrapper {
          margin 8px 0 16px 0
          width 100%
        
          &--center {
            display flex
            justify-content center
          }
        }
        
        .label {
          font-size 16px
          font-weight 500
        }
        
        .preview {
          width 200px
          height 200px
          align-self center
          
          > img {
            width 100%
            height 100%
            object-fit contain
          }
        }
      `}</style>
    </Modal>
  )
}
