import React from "react"
import { Header } from "../components/Header"
import { useTranslation } from "react-i18next"
import { Button } from "../components/Button"
import { useHistory } from "react-router-dom"
import InstructionSection from "../components/InstructionSection"

export const InstructionPage = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={"container"}>
      <Header title={t('guide.header')}/>

      <div>
        <InstructionSection title={t("guide.note")} content={t("guide.note_message")} />
        <InstructionSection
          title={t("guide.how_to_take")}
          content={t("guide.how_to_take_message")}
        />
        <InstructionSection
          title={t("guide.equipment")}
          content={t("guide.equipment_message")}
        />

        <InstructionSection
          title={t("guide.background")}
          content={t("guide.background_message")}
        />
        <InstructionSection
          title={t("guide.lighting_flash")}
          content={t("guide.lighting_flash_message")}
        />
        <InstructionSection
          title={t("guide.clothes")}
          content={t("guide.clothes_message")}
        />
        <InstructionSection
          title={t("guide.glasses")}
          content={t("guide.glasses_message")}
        />
        <InstructionSection title={t("guide.hair")} content={t("guide.hair_message")} />
        <InstructionSection title={t("guide.face")} content={t("guide.face_message")} />
        <InstructionSection
          title={t("guide.do_not_edit")}
          content={t("guide.do_not_edit_message")}
        />
        <InstructionSection title={t("guide.bad_source_photo_examples")} content={""} />
        <img
          style={{ width: "100%" }}
          src="https://storage.googleapis.com/aha-photolab-assets/kao/visa-error-preview.png"
          alt=""
        />
      </div>

      <Button
        padding={16}
        title={t("button.understand_countinues")}
        onClick={() => {
          history.push({
            pathname: "camera",
          })
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          padding-bottom 16px
        }
      `}</style>
    </div>
  )
}
