import React from "react"

type ActionFixedProps = {
  fixed?: boolean
}

export const ActionFixed: React.FC<ActionFixedProps> = ({
  children,
  fixed
}) => {
  return (
    <div
      className={"actions"}
      style={fixed ? { display: "flex", alignItems: "center" } : {}}
    >
      {fixed ? children : <div className={"actions__fixed"}>{children}</div>}

      {/*language=Stylus*/}
      <style jsx>{`
        .actions {
          //transform translate3d(0,0,0)
          
          &__fixed {
            position fixed
            z-index 800
            bottom 16px
            padding 0 16px
            left 0
            right 0
            display flex
            justify-content space-around
            align-items center
          }
        }
      `}</style>
    </div>
  )
}

ActionFixed.defaultProps = {
  fixed: true
}
