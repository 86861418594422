import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Process } from "../components/Process"
import { CloseIcon, PencilIcon } from "../assets"
import { Button } from "../components/Button"
import { Divider } from "./CameraPage"
import { useTranslation } from "react-i18next"
import { EditModal } from "../components/EditModal"
import { Photo } from "../interfaces"
import { useHistory } from "react-router-dom"
import { toBase64 } from "../functions/file-extra"
import { nanoid } from "nanoid"
import { ActionFixed } from "../components/ActionFixed"
import store from "../store"
import { Alert } from "../components/Alert"
import axios, { CancelTokenSource } from "axios"
import { useAsync } from "react-use"
import produce from "immer"
import { ImageObj } from "../common/types"

const ImageOverlay: React.FC<any> = ({ children, onClick }) => {
  return (
    <div className={"image-overlay"} onClick={onClick}>
      {children}
      {/*language=Stylus*/}
      <style jsx>{`
        .image-overlay {
          position absolute
          top 0
          left 0
          right 0
          bottom 24px
          display flex
          justify-content center
          align-items center
          padding 8px
          background rgba(0, 0, 0, 0.6)
        }

      `}</style>
    </div>
  )
}

type ProgressBar = {
  percentCompleted?: number
}

const ProgressBar: React.FC<ProgressBar> = ({ percentCompleted = 0 }) => {
  return (
    <ImageOverlay>
      <div className={"outer"}>
        <div className={"inner"} style={{ width: `${percentCompleted}%` }} />
      </div>

      {/*language=Stylus*/}
      <style jsx>{`

        .outer {
          height 8px
          width 100%
          background white
          border-radius 4px
          overflow hidden
        }

        .inner {
          height 8px
          background #FF8200
          transition all 0.3s ease-in-out
        }
      `}</style>
    </ImageOverlay>
  )
}

const ProcessText: React.FC<any> = ({ message, onClick }) => {
  return (
    <ImageOverlay onClick={onClick}>
      <span>{message}</span>

      {/*language=Stylus*/}
      <style jsx>{`
        span {
          color #FF8200
          font-size 16px
        }
      `}</style>
    </ImageOverlay>
  )
}

type PhotoPreviewProps = Photo & {
  onEdit(): void
  onRemove(id: string): void
  onRetry(id: string): void
  onError(): void
  percentCompleted?: number
  imageLoading: boolean
  error: string
}

const PhotoPreviewError = ({ error, originalFilename, onRetry, id }) => {
  if (error) {
    return (
      <ProcessText message={error.toString()} onClick={() => onRetry(id)} />
    )
  }

  // if (!originalFilename) {
  //   return <ProcessText message={"Ảnh tải lên đã bị lỗi, vui lòng thử lại"} onClick={() => onRetry(id)} />
  // }

  return null
}

const PhotoPreview: React.FC<PhotoPreviewProps> = ({
  id,
  isProcessed,
  totalImages,
  originalFilename,
  thumbnailUrl,
  onEdit,
  onRemove,
  onRetry,
  onError,
  error,
  label,
  percentCompleted = -1,
  imageLoading
}) => {
  const isCompleted =
    (percentCompleted === -1 ? true : percentCompleted === 100) && !error
  const isProcessing = imageLoading && isCompleted && !error
  const { t } = useTranslation()

  return (
    <div className={"photo"}>
      <div className={"photo__icon"} onClick={() => onRemove(id)}>
        <CloseIcon />
      </div>
      <div className={"photo__nums"}>x{totalImages}</div>
      {isProcessed && (
        <div className={"photo__edit"} onClick={onEdit}>
          <PencilIcon />
        </div>
      )}

      {!error && (
        <>
          {!isCompleted && <ProgressBar percentCompleted={percentCompleted} />}

          {isProcessing && <ProcessText message={t("text.processing_image")} />}
        </>
      )}

      <PhotoPreviewError
        error={error}
        id={id}
        onRetry={onRetry}
        originalFilename={originalFilename}
      />

      <img src={thumbnailUrl} alt={""} onError={onError} />
      <span>{label}</span>

      {/*language=Stylus*/}
      <style jsx>{`
        .photo {
          width 100%
          border 1px solid #1A1A1A
          position relative

          &__icon {
            position absolute
            top -15px
            left -15px
            z-index 10
          }

          &__nums {
            position absolute
            top 0
            right 0
            padding 4px 6px
            font-size 20px
            background #FF8200
            color white
            border-bottom-left-radius 8px
            z-index 10
          }

          &__edit {
            position absolute
            right 0
            bottom 24px
            padding 4px 6px
            background #142246
            opacity 0.8
            color white
            border-top-left-radius 8px
            z-index 10
          }

          img {
            width 100%
            height calc(100vw / 2)
            object-fit cover
          }

          span {
            display block
            height 24px
            line-height 20px
            text-align center
          }
        }
      `}</style>
    </div>
  )
}

type Obj<T> = {
  [key: string]: T
}

const SIZE_LIMIT = 210_000_000

export const ProcessPhotosPage = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const inputRef = useRef<any>(null)

  const [showEdit, setShowEdit] = useState(false)
  const [currentPhoto, setCurrentPhoto] = useState<Photo | null>(null)
  const [photos, setPhotos] = useState<Photo[]>([])
  const [error, setError] = useState("")
  const [percentCompleted, setPercentCompleted] = useState<Obj<number>>({})
  const [imagesLoading, setImagesLoading] = useState<Obj<boolean>>({})
  const [cancelTokens, setCancelTokens] = useState<Obj<CancelTokenSource>>({})
  const [imagesError, setImagesError] = useState<Obj<string>>({})
  const [sizes, setSizes] = useState<Obj<number>>({})

  useEffect(() => {
    const rawSizes = localStorage.getItem("sizes")

    if (rawSizes) {
      const sizes = JSON.parse(rawSizes)

      setSizes(sizes)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("sizes", JSON.stringify(sizes))
  }, [sizes])

  const totalSize = useMemo(
    () => Object.values(sizes).reduce((acc, cur) => acc + cur, 0),
    [sizes]
  )

  const isImagesLoaded = useMemo(() => {
    const arr = Object.values(imagesLoading)

    if (arr.length === 0) {
      return true
    }

    return arr.every(o => !o)
  }, [imagesLoading])

  const onUpdatePhotos = useCallback(
    (photo: Photo) => {
      const index = photos.findIndex(o => o.id === photo.id)
      setPhotos(photos => Object.assign([], photos, { [index]: photo }))
    },
    [photos]
  )

  const onRemovePhoto = useCallback(
    (id: string) => {
      setPhotos(photos => photos.filter(o => o.id !== id))
      setSizes(prevState =>
        produce(prevState, draft => {
          delete draft[id]
        })
      )

      if (cancelTokens[id]) {
        cancelTokens[id].cancel()
      }

      setImagesLoading(prevState =>
        produce(prevState, draft => {
          delete draft[id]
        })
      )

      setPercentCompleted(prevState =>
        produce(prevState, draft => {
          delete draft[id]
        })
      )
    },
    [cancelTokens]
  )

  const onUploadImage = useCallback((photo: Photo) => {
    const file = photo.file

    const formData = new FormData()
    setImagesLoading(prevState => ({
      ...prevState,
      [photo.id]: true
    }))

    setImagesError(prevState =>
      produce(prevState, draft => {
        delete draft[photo.id]
      })
    )

    formData.append(file!.name, file!)

    const source = axios.CancelToken.source()

    setCancelTokens(prevState => ({ ...prevState, [photo.id]: source }))

    axios({
      url: `${process.env.REACT_APP_UPLOADER_URL}/v2/upload`,
      method: "POST",
      data: formData,
      cancelToken: source.token,
      headers: {
        "Content-Type": "multipart/form-data",
        "Application-Type": "photo",
        "Application-Stage": process.env.REACT_APP_STAGE
      },
      onUploadProgress(e) {
        const percentCompleted = Math.round((e.loaded * 100) / e.total)
        // console.log(percentCompleted)

        setPercentCompleted(prevState => ({
          ...prevState,
          [photo.id]: percentCompleted
        }))
      }
    })
      .then(resp => resp.data)
      .then((data: ImageObj[]) => {
        setImagesLoading(prevState => ({
          ...prevState,
          [photo.id]: false
        }))

        setPhotos(prevState => {
          return prevState.map(p => {
            if (p.id === photo.id) {
              return {
                ...p,
                filename: data[0].hash,
                originalFilename: data[0].original,
                ext: data[0].ext,
                file: null,
                isProcessed: true,
                thumbnailUrl: data[1].signedUrl,
                imageUrl: data[0].signedUrl
              }
            }

            return p
          })
        })
      })
      .catch(err => {
        setImagesError(prevState => ({
          ...prevState,
          [photo.id]: err
        }))
      })
  }, [])

  const onRetry = useCallback(
    async (id: string) => {
      const currentPhoto = photos.find(photo => photo.id === id)

      if (currentPhoto) {
        onUploadImage(currentPhoto)

        setImagesLoading(prevState =>
          produce(prevState, draft => {
            draft[id] = true
          })
        )
      }
    },
    [photos, onUploadImage]
  )

  const onUpload = async e => {
    const fileList: FileList = e.target.files

    if (fileList) {
      const files = Array.from(fileList)

      const totalCurrentSize = files.reduce((acc, file) => acc + file.size, 0)

      if (totalSize + totalCurrentSize > SIZE_LIMIT) {
        setError("Has exceed the limit")
        return
      }

      const isValid = files.every(file => {
        if (!file.type.startsWith("image")) {
          setError(t("text.doesnt_support_file_format"))
          return false
        }

        return true
      })

      if (!isValid) {
        return
      }

      const urls = await Promise.all(files.map(toBase64))
      const currentPhotos: Photo[] = files.map((file, index) => ({
        id: nanoid(10),
        totalImages: 1,
        imageUrl: "",
        thumbnailUrl: urls[index],
        file,
        filename: "",
        originalFilename: "",
        price: 0,
        ext: "",
        label: "6x9 cm",
        printingSize: {
          size: "6x9",
          unit: "cm"
        }
      }))

      setSizes(prevState =>
        produce(prevState, draft => {
          currentPhotos.forEach(photo => {
            draft[photo.id] = photo.file?.size ?? 0
          })
        })
      )

      setPhotos(prevState => prevState.concat(currentPhotos))

      currentPhotos.forEach(photo => {
        onUploadImage(photo)
      })
    }
  }

  useAsync(async () => {
    const cachedPhotos: Photo[] = JSON.parse(
      localStorage.getItem("photos") ?? "[]"
    )

    setPhotos(cachedPhotos)
  }, [])

  useEffect(() => {
    const newPhotos = photos
      .map(photo => {
        if (!photo.thumbnailUrl.startsWith("https://")) {
          return null
        }

        if (!photo.originalFilename) {
          return null
        }

        if (photo.isProcessed) {
          return photo
        }

        return null
      })
      .filter(o => !!o)

    store.photos = newPhotos
    localStorage.setItem("photos", JSON.stringify(newPhotos))
  }, [photos])

  return (
    <div>
      <Process />
      <h2 className={"title"}>{t("text.photo")}</h2>
      <span className={"subtitle"}>{t("text.normal_photo_subtitle")}</span>

      <div className={"photos"}>
        {photos.map(photo => (
          <PhotoPreview
            key={photo.id}
            {...photo}
            percentCompleted={percentCompleted[photo.id]}
            error={imagesError[photo.id]}
            imageLoading={imagesLoading[photo.id]}
            onEdit={() => {
              setCurrentPhoto(photo)
              setShowEdit(true)
            }}
            onError={() => {
              setPhotos(prevState => prevState.filter(p => p.id !== photo.id))
            }}
            onRetry={onRetry}
            onRemove={onRemovePhoto}
          />
        ))}
      </div>

      <input
        accept="image/*"
        type="file"
        id={"files"}
        name={"files"}
        onChange={onUpload}
        multiple
        value=""
        style={{ display: "none" }}
        ref={inputRef}
      />

      <ActionFixed fixed={false}>
        <Button
          background={"#F2F2F2"}
          color={"#142246"}
          title={t("button.add_photo")}
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.click()
            }
          }}
        />
        <Divider width={15} />
        <Button
          title={t("button.continue_confirm")}
          disabled={photos.length === 0 || !isImagesLoaded}
          onClick={() => {
            history.push({
              pathname: "preview-photos"
            })
          }}
        />
      </ActionFixed>

      <Alert
        visible={!!error}
        title={t("text.sth_went_wrong")}
        message={error}
        buttonTitle={t("button.i_got_it")}
        onClick={() => {
          setError("")
        }}
      />

      {currentPhoto && currentPhoto.isProcessed && (
        <EditModal
          visible={showEdit}
          data={currentPhoto}
          onClose={() => {
            setShowEdit(false)
          }}
          onUpdate={data => {
            onUpdatePhotos(data)
            setShowEdit(false)
          }}
        />
      )}

      {/*language=Stylus*/}
      <style jsx>{`
        .title {
          font-size 16px
          font-weight 500
          margin-top 12px
          margin-bottom 4px
          text-align center
        }

        .subtitle {
          font-size 12px
          line-height 16px
          margin 0 32px
          display block
          text-align center
        }

        .photos {
          display grid
          grid-gap 24px
          grid-template-columns 1fr 1fr
          margin 16px
          padding-bottom 100px
        }
      `}</style>
    </div>
  )
}
