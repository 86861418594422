import React from "react"

export type SwitchCamButtonProps = {
  onClick(): void
}

export const SwitchCamButton: React.FC<SwitchCamButtonProps> = ({
  onClick
}) => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M16.6518 2.36366L16.3658 2.84839C12.8377 2.60743 9.22919 3.83612 6.53239 6.53291C2.26441 10.8008 1.67407 17.3527 4.76266 22.2518L6.53378 20.4807L7.5299 27.4553L0.555343 26.4592L2.72797 24.2865C-1.43703 18.2474 -0.832569 9.91319 4.54002 4.54061C8.38097 0.698246 13.738 -0.705116 18.6864 0.329068L16.6518 2.36366Z"
        fill="white"
      />
      <path
        d="M22.4724 2.54833L29.4471 3.54449L27.411 5.58189C32.4934 11.6716 32.1749 20.7442 26.4586 26.4606C22.2118 30.7073 16.1136 31.974 10.7622 30.2621L13.0744 27.9499C17.0703 28.7249 21.3692 27.5639 24.4648 24.4682C29.0792 19.8524 29.3936 12.565 25.406 7.58555L23.4686 9.52292L22.4724 2.54833Z"
        fill="white"
      />
      <ellipse
        opacity="0.5"
        cx="15.5"
        cy="15.5"
        rx="2.5"
        ry="2.5"
        fill="white"
      />
    </svg>
  )
}
