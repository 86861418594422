import React, { useCallback, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAsync } from "react-use"
import Select  from "react-select"
import photoStandards from "data/photo-standards.json"
import axios, { ResponseType } from "axios"
import { blobToString } from "functions/file-extra"
import { Button } from "../components/Button"
import { Header } from "../components/Header"
import { Process } from "../components/Process"
import { NumberInput } from "../components/NumberInput"
import { Alert } from "../components/Alert"
import Color from "color"
import { Loading } from "../components/Loading"
import { useTranslation } from "react-i18next"
import { LoadingAnim, PhotoIcon } from "../assets"
import imageCompression from "browser-image-compression"
import { PhotoStandard } from "../models/datatypes"
import { nanoid } from "nanoid"
import store from "../store"
import { ImageObj } from "../common/types"

export const PreviewPage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [value, setValue] = useState<PhotoStandard | null>(null)
  const [optimizedImage, setOptimizedImage] = useState<File | null>(null)
  const [url, setUrl] = useState<string | undefined>("")
  const [numOfPhotos, setNumOfPhotos] = useState(4)
  const [bgColor, setBgColor] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [cachePhotos, setCachePhotos] = useState(new Map())
  const [passportPhoto, setPassportPhoto] = useState<File | null>(null)

  const onRemoveBg = useCallback(async (file: File, bgColor: string) => {
    setLoading(true)
    const formData = new FormData()
    formData.append("file", file)
    // formData.append("size", "full")
    const color = Color(bgColor)
      .rgb()
      .array()
    formData.append("background", JSON.stringify(color))
    const response = await axios.post(
      `${process.env.REACT_APP_REMOVE_BG_URL!}/remove-bg`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
          // "X-API-Key": "KhQYTvyBJ1vErrnf1kxdts6w"
        },
        responseType: "blob"
      }
    )

    return response.data
  }, [])

  const onCreateIDImage = useCallback(
    async (type: string, file: File, responseType: ResponseType) => {
      try {
        if (!value) {
          return
        }

        const photoStandard = value
        const formData = new FormData()
        formData.append("image", file)
        formData.append(
          "photo_standard",
          JSON.stringify(photoStandard.dimensions)
        )
        formData.append("stage", process.env.REACT_APP_STAGE!)
        formData.append("num_photos", numOfPhotos.toString())
        const color = Color(bgColor)
          .rgb()
          .array()
        formData.append("background", JSON.stringify(color))
        formData.append(
          "print_definition",
          JSON.stringify(
            type === "preview"
              ? {
                  id: "ds",
                  text: "Digital Size",
                  height: 0,
                  width: 0,
                  resolution: 0
                }
              : {
                  id: "6x4",
                  text: "6″ x 4″",
                  height: 6,
                  width: 8,
                  resolution: 300,
                  units: "inch",
                  gutter: 0.02,
                  padding: 0.06
                }
          )
        )

        const response = await axios.post(
          `${process.env.REACT_APP_KAO_URL}/${type}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            responseType
          }
        )

        return response.data
      } catch (e) {
        const err: any = await blobToString(e.response.data)
        const errData = JSON.parse(err)

        if (errData) {
          throw errData
        }

        throw new Error("Something went wrong. Please try again")
      }
    },
    [bgColor, numOfPhotos, value]
  )

  useAsync(async () => {
    setLoading(true)
    const file = store.files[0]
    const exifOrientation = await imageCompression.getExifOrientation(file)

    const compressedImage: any = await imageCompression(file, {
      useWebWorker: true,
      maxWidthOrHeight: 2000,
      fileType: "image/png",
      exifOrientation
    })

    setOptimizedImage(compressedImage)
    setLoading(false)
  }, [])

  useAsync(async () => {
    try {
      if (!optimizedImage || !value) {
        return
      }

      setLoading(true)
      let file = cachePhotos.get(bgColor)

      if (!file) {
        file = await onRemoveBg(optimizedImage, bgColor)
        cachePhotos.set(bgColor, file)
        setCachePhotos(cachePhotos)
      }

      const result = await onCreateIDImage("preview", file, "blob")

      if (url) {
        URL.revokeObjectURL(url)
      }
      const newUrl = URL.createObjectURL(result)
      setPassportPhoto(result)
      setUrl(newUrl)
      setLoading(false)
    } catch (e) {
      setLoading(false)

      if (e.message) {
        if (e.code === 1000) {
          setError(
            t("error.cant_detect_any_face", {
              url: "https://kao.ahamove.net/instructions"
            })
          )
          return
        }

        if (e.message === "Network Error") {
          setError(t("error.network_error"))
          return
        }

        setError(e.message)
        return
      }
    }
  }, [value, optimizedImage, bgColor])

  const onChangePhotoType = useCallback((value: any) => {
    if (value) {
      setBgColor(value.backgroundColor)
    }
    setValue(value)
    setUrl("")
    setNumOfPhotos(value?.price_per_pack_value ?? 4)
  }, [])

  const onSubmit = useCallback(async () => {
    try {
      if (!value) {
        return
      }

      setLoading(true)
      setSubmitLoading(true)

      if (!passportPhoto) {
        throw new Error("Can not find file")
      }

      const formData = new FormData()
      formData.append('file', passportPhoto)

      const data: ImageObj[] = await axios({
        url: `${process.env.REACT_APP_UPLOADER_URL}/v2/upload`,
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "Application-Type": "passport",
          "Application-Stage": process.env.REACT_APP_STAGE,
        },
      })
        .then(resp => resp.data)
      // const data = await onCreateIDImage("tiled", passportPhoto, "json")

      if (!data) {
        throw new Error("Missing data")
      }

      setSubmitLoading(false)
      setLoading(false)

      store.photos = [
        {
          id: nanoid(10),
          totalImages: numOfPhotos,
          filename: data[0].hash,
          originalFilename: value.text,
          visaType: value.text,
          thumbnailUrl: data[1].signedUrl,
          imageUrl: data[0].signedUrl,
          label: value.text,
          ext: data[0].ext,
          pricePerPack: value.price_per_pack,
        }
      ]

      history.push({
        pathname: "checkout",
      })
    } catch (e) {
      setError(e.message)
      setSubmitLoading(false)
      setLoading(false)
    }
  }, [
    value,
    numOfPhotos,
    history,
    passportPhoto
  ])

  return (
    <div className={"container"}>
      <Header />
      <Process step={1} />

      <div className={"wrapper"}>
        <span className={"wrapper__title"}>{t("text.select_photo_type")}</span>
        <Select<PhotoStandard>
          isMulti={false}
          isDisabled={loading}
          isSearchable={true}
          isClearable={true}
          closeMenuOnSelect={true}
          value={value}
          getOptionLabel={v => v.text}
          getOptionValue={v => v.text}
          onChange={onChangePhotoType}
          options={photoStandards}
        />
      </div>

      <div className={"wrapper"}>
        <span className={"wrapper__title"}>
          {t("text.select_number_photo")}
        </span>
        <div className={"wrapper__number-input"}>
          <NumberInput
            value={numOfPhotos}
            step={value?.price_per_pack_value ?? 4}
            onChange={value => {
              setNumOfPhotos(value)
            }}
            min={value?.price_per_pack_value ?? 4}
          />
        </div>
      </div>

      <div className={"image"}>
        {/*<div ref={loadingRef} className={"image__loading"}/>*/}
        {!!url ? (
          <img src={url} alt="" />
        ) : loading ? (
          <LoadingAnim />
        ) : (
          <PhotoIcon width={120} height={120} />
        )}
      </div>

      <Loading visible={submitLoading} />

      <Button
        disabled={loading || !url}
        absolute
        title={
          submitLoading || loading
            ? t("button.processing")
            : t("button.continue")
        }
        onClick={onSubmit}
        loading={submitLoading}
      />

      <Alert
        visible={!store.hasFiles}
        buttonTitle={t("button.go_back")}
        message={t("text.missing_image")}
        title={t("text.sth_went_wrong")}
        onClick={() => {
          history.replace("camera")
        }}
      />

      <Alert
        visible={!!error}
        buttonTitle={t("button.go_back")}
        message={error}
        title={t("text.sth_went_wrong")}
        onClick={() => {
          history.replace("camera")
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          //display flex
          //flex-direction column
          height 100vh
        }
        
        .image {
          display flex
          justify-content center
          align-items center
          //margin-top 100px
          //margin-bottom 82px
          flex 1
          position relative
          padding 40px
          padding-bottom 120px
        
          img {
            width 80%
            padding 8px
            background #EAF0FF
          }
          
          &__loading {
            width 100px
            height 100px
          }
        }
        
        .wrapper {
          padding 16px
          padding-bottom 0
          
          &__title {
            font-size 14px
            line-height 20px
            font-weight 500
            margin-bottom 2px
          }
          
          &__number-input {
            margin-top 12px
            flex 1
            display flex
            justify-content center
          }
        }
      `}</style>
    </div>
  )
}
