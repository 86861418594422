class Store {
  lang = ""
  token = ""
  photoType = "passport"
  files: File[] = []
  photos: any[] = []
  path: any = {}
  order: any = {}
  services = []

  getService(cityId: string) {
    return this.services.find(service => service._id === `${cityId}-${this.photoType.toUpperCase()}`)
  }

  get hasFiles() {
    return this.files.length !== 0
  }
}

const store = new Store()

export default store
