import React, { useEffect, useState } from "react"
import {
  AhamoveIcon,
  CameraIcon,
  CardPhotoIcon,
  ImageIcon,
  NormalPhotoIcon,
  TickIcon
} from "assets"
import { Button } from "../components/Button"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useLang } from "../hooks/useLang"
import { useToken } from "../hooks/useToken"
import store from "../store"
import { Alert } from "../components/Alert"
import { useQueryString } from "../hooks/useQueryString"
import { useAsync } from "react-use"
import axios from "axios"
import { prefetchApps } from "qiankun"

type OptionItemProps = {
  icon: JSX.Element
  onClick(printingType: string): void
  printingType: string
  title: string
  subtitle: string
  isSelected: boolean
}

const OptionItem: React.FC<OptionItemProps> = ({
  icon,
  onClick,
  printingType,
  title,
  subtitle,
  isSelected
}) => {
  return (
    <div
      className={"option-item"}
      onClick={() => onClick(printingType)}
      style={{
        border: isSelected ? "2px solid #FF8200" : "2px solid transparent"
      }}
    >
      {isSelected && (
        <div className={"tick-icon"}>
          <TickIcon />
        </div>
      )}
      {icon}
      <h3>{title}</h3>
      <span>{subtitle}</span>

      {/*language=Stylus*/}
      <style jsx>{`
        .tick-icon {
          position absolute
          top 8px
          right 8px
        }
        
        .option-item {
          position relative
          border-radius 4px
          display flex
          flex-direction column
          align-items center
          text-align center
          padding 16px
          cursor pointer
          height 188px
          
          h3 {
            font-size 16px
            font-weight 500
            color #1A1A1A
            margin-top 16px
            margin-bottom 4px
          }
          
          > span {
            font-size 14px
            line-height 16px
          }
        }
      `}</style>
    </div>
  )
}

export const WelcomePage = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const lang = useLang()
  const token = useToken()
  const qs = useQueryString()

  const [error, setError] = useState("")
  const [photoType, setPhotoType] = useState<string>(
    qs.type === "photo" ? "photo" : "passport"
  )
  const isNormalPhoto = photoType === "photo"

  useEffect(() => {
    store.lang = lang
    store.token = token
    store.photoType = photoType
  }, [token, lang, photoType])

  useAsync(async () => {
    try {
      store.services = await axios.get(`${process.env.REACT_APP_MINI_APP_URL}/v1/service/list`, {
        params: {
          ids: [
            `SGN-${photoType.toUpperCase()}`,
            `HAN-${photoType.toUpperCase()}`
          ].join(",")
        }
      }).then(result => result.data)

      console.log('store.services', store.services)
    } catch (e) {
      console.log(e)
    }
  }, [photoType])

  useEffect(() => {
    prefetchApps([
      { name: "mini-checkout", entry: process.env.REACT_APP_MINI_CHECKOUT },
    ])
  }, [])

  return (
    <div className={"container"}>
      <h1 className={"title"}>{t("app.name")}</h1>
      <h3 className={"subtitle"}>{t("text.easily_take_photo")}</h3>

      <div className={"instruction"}>
        <div className={"instruction__content"}>
          <div className={"instruction__content__item"}>
            <div className={"instruction__content__icon"}>
              <CameraIcon />
            </div>
            <span>{t("text.take_photo")}</span>
          </div>
          <div className={"instruction__content__item"}>
            <div className={"instruction__content__icon"}>
              <ImageIcon />
            </div>
            <span>{t("text.select_photo_type")}</span>
          </div>
          <div className={"instruction__content__item"}>
            <div className={"instruction__content__icon"}>
              <AhamoveIcon />
            </div>
            <span>{t("text.delivery")}</span>
          </div>
        </div>
      </div>

      <span className={"choosing-option-title"}>{t("text.pls_select")}</span>

      <div className={"option-menu"}>
        {qs.type === "photo" ? (
          <OptionItem
            icon={<NormalPhotoIcon />}
            onClick={setPhotoType}
            printingType={"photo"}
            title={t("text.photo")}
            subtitle={t("text.normal_photo_subtitle")}
            isSelected={isNormalPhoto}
          />
        ) : (
          <OptionItem
            icon={<CardPhotoIcon />}
            onClick={setPhotoType}
            printingType={"passport"}
            title={"Passport"}
            subtitle={t("text.passport_photo_subtitle")}
            isSelected={!isNormalPhoto}
          />
        )}
      </div>

      <Alert
        visible={!!error}
        title={t("text.sth_went_wrong")}
        message={error}
        buttonTitle={t("button.i_got_it")}
        onClick={() => {
          setError("")
        }}
      />

      <Button
        padding={16}
        title={t("button.get_started")}
        onClick={() => {
          if (!token) {
            setError(t("text.missing_token"))
            return
          }

          history.push({
            pathname: isNormalPhoto ? "photo-instruction" : "instruction"
          })
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          display flex
          flex-direction column
          justify-content center
          align-items center
          //padding-bottom 66px
        }

        .image {
          margin-top 18px
        }
        
        .title {
          font-size 32px
          line-height 38px
          color #FF8200
          margin-top 50px
          font-weight 500
        }
        
        .subtitle {
          font-size 16px
          line-height 24px
          font-weight 500
          text-align center
          margin 16px 38px 0
        }
        
        .instruction {
          padding 12px
          width fill-available
          margin 16px
          background #F9F9F9
          border-radius 4px
          display flex
          justify-content center
          
          &__content {
            width fit-content
            height 104px
            display flex
            flex-direction column
            justify-content space-around
            
            &__item {
              display flex
              align-items center
              font-size 12px
              color #7A8189
            }
            
            &__icon {
              border-radius 50%
              background #fff
              height 24px
              width 24px
              display flex
              justify-content center
              align-items center
              margin-right 12px
            }
          }
        }
        
        .choosing-option-title {
          font-size 16px
          color rgba(0, 0, 0, 0.65)
          font-weight 500
        }
        
        .option-menu {
          margin 16px
          width fill-available
          display grid
          //grid-gap 10px
          //grid-template-columns 1fr 1fr
        }
      `}</style>
    </div>
  )
}
