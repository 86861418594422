export function urlToFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(res => {
      return res.arrayBuffer()
    })
    .then(buf => {
      return new File([buf], filename, { type: mimeType })
    })
}

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

export const blobToString = async (blob: Blob) => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = function() {
      resolve(reader.result)
    }
    reader.readAsText(blob)
  })
}
