import { Button } from "./Button"
import React from "react"
import { Modal } from "./Modal"

export type AlertProps = {
  visible: boolean
  title: string
  message: string
  buttonTitle?: string
  onClick(): void
}

export const Alert: React.FC<AlertProps> = ({
  visible,
  title,
  message,
  buttonTitle,
  onClick
}) => {
  return (
    <Modal visible={visible}>
      <div className={"content"}>
        <h3 className={"content__title"}>{title}</h3>
        <span className={"content__message"}>{message}</span>
        <Button title={buttonTitle!} onClick={onClick} />
      </div>

      {/*language=Stylus*/}
      <style jsx>{`
        .content {
          padding 16px 32px
          background #FFF
          animation popup 0.7s
          width 400px
          border-radius 4px
          z-index 999
          
          @media only screen and (max-width 830px) {
            width 100%
          }
          
          &__title {
            font-weight 500
            font-size 16px
            line-height 20px
            text-align center
          }
          
          &__message {
            line-height 20px
            text-align center
            display inline-block
            width 100%
            color rgba(0, 0, 0, 0.65)
            margin-bottom 16px
          }
        }
      `}</style>
    </Modal>
  )
}

Alert.defaultProps = {
  buttonTitle: "OK"
}
