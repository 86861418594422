import React from "react"
import { Header } from "../components/Header"
import { Process } from "../components/Process"
import { Button } from "../components/Button"
import { useHistory } from "react-router-dom"
import { TextInput } from "../components/TextInput"
import { useLang } from "../hooks/useLang"

export const SignUpPage = () => {
  const history = useHistory()
  useLang()

  return (
    <div className={"container"}>
      <Header />
      <Process step={2} />
      <h3 className={"title"}>Please sign up to processed</h3>

      <div className={"content"}>
        <TextInput title={"Name"} placeholder={"Your name"} />
        <TextInput title={"Number Phone"} placeholder={"Your number phone"} />
        <TextInput title={"Email"} placeholder={"abc@gmail.com"} />
      </div>

      <Button
        absolute
        disabled={false}
        title={"Sign up"}
        onClick={() => {
          history.push("camera")
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .title {
          color #7A8189
          font-weight 500
          padding 14px
          text-align center
        }
        
        .content {
          padding 16px
        }
      `}</style>
    </div>
  )
}
