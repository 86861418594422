import React, { useEffect, useRef, useState } from "react"
import { loadMicroApp, MicroApp } from "qiankun"
import store from "store"
import { Loading } from "../components/Loading"

const getPriceBySize = (pricePerSize: any[], size: string) => {
  const price = pricePerSize[size]

  if (!price) {
    return 25_000
  }

  return price
}

const calcPrice = (service: any, photo: any) => {
  const pricePerPack = service.price_per_pack[photo.config.price_per_pack] ?? [
    4,
    30_000
  ]

  if (photo.config.country) {
    return pricePerPack[1] * (photo.config.num / pricePerPack[0])
  }

  return (
    getPriceBySize(service.price_by_size, photo.config.size) * photo.config.num
  )
}

const Checkout = () => {
  const containerRef = useRef()
  const microApp = useRef<MicroApp | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const items = store.photos.map(f => ({
      name: f.originalFilename,
      num: f.totalImages,
      price: -1
    }))

    const data = store.photos.map(f => ({
      config: {
        type: store.photoType.toUpperCase(),
        num: f.totalImages,
        size: f.printingSize?.size,
        unit: f.printingSize?.unit,
        price_per_pack: f.price_per_pack || "default",
        country: f.visaType
      },
      thumbnail: f.thumbnailUrl,
      file_url: f.imageUrl,
      filename: f.filename,
      original_filename: f.originalFilename,
      ext: f.ext,
      price: -1
    }))

    console.log(store.photoType.toUpperCase())
    microApp.current = loadMicroApp({
      name: "mini-checkout",
      entry: process.env.REACT_APP_MINI_CHECKOUT,
      container: containerRef.current,
      props: {
        service: store.photoType.toUpperCase(),
        token: store.token,
        items,
        data,
        onFindMerchantDone(merchant, done) {
          const service = store.getService(merchant.city_id)

          if (!service) {
            throw new Error(`Dịch vụ không tồn tại trong khu vực ${merchant.city_id}`)
          }

          const newData = data.map(el => ({
            ...el,
            price: calcPrice(service, el)
          }))

          const newItems = newData.map(el => ({
            name: el.original_filename,
            num: el.config.num,
            price: el.price
          }))

          done(newItems, newData)
        },
        onBookingDone() {
          localStorage.removeItem("sizes")
          localStorage.removeItem("photos")
        }
      }
    })

    // set loading
    const timer = setInterval(() => {
      if (microApp.current) {
        const status = microApp.current.getStatus()

        if (status !== "LOADING_SOURCE_CODE") {
          setLoading(false)
          clearInterval(timer)
        }
      }
    }, 500)

    return () => microApp.current?.unmount()
  }, [])

  return (
    <div style={{ paddingBottom: '0.7rem' }}>
      <Loading visible={loading} />
      <div ref={containerRef} />
    </div>
  )
}

export default Checkout
