import React from "react"
import cx from "classnames"

// export type ModalProps = {
//   visible: boolean
// }

// export const Modal: React.FC<ModalProps> = ({ visible, children }) => {
//   return (
//     <modal
//       showCloseButton={false}
//       width={"auto"}
//       height={"auto"}
//       visible={visible}
//     >
//       {children}
//     </modal>
//   )
// }

// env
const IN_BROWSER = typeof window !== "undefined"
const UA = IN_BROWSER && window.navigator.userAgent.toLowerCase()
const IS_IE_9 = UA && UA.indexOf("msie 9.0") > 0

const Dialog = props => {
  const animation =
    (props.animationType === "enter"
      ? props.enterAnimation
      : props.leaveAnimation) || props.animation

  const className = `modal-dialog modal-${animation}-${props.animationType}`

  const CloseButton = props.showCloseButton ? (
    <span
      className="modal-close"
      onClick={props.onClose}
      onKeyPress={event => {
        if (props.onClose && event.which === 13) {
          props.onClose(event)
        }
      }}
      tabIndex={0}
    />
  ) : null

  const { duration, customStyles } = props

  const style = {
    animationDuration: duration + "ms",
  }

  const mergedStyles = { ...style, ...customStyles }

  return (
    <div style={mergedStyles} className={className}>
      {props.children}
      {CloseButton}
    </div>
  )
}

export class Modal extends React.Component<any, any> {
  static defaultProps = {
    width: 400,
    height: 240,
    measure: "px",
    visible: false,
    showMask: true,
    closeOnEsc: false,
    closeMaskOnClick: true,
    showCloseButton: false,
    animation: "zoom",
    enterAnimation: "",
    leaveAnimation: "",
    duration: 300,
    className: "",
    customStyles: {},
    customMaskStyles: {}
  }

  state = {
    isShow: false,
    animationType: "leave"
  }

  componentDidMount() {
    if (this.props.visible) {
      this.enter()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.enter()
    }

    if (!this.props.visible && prevProps.visible) {
      this.leave()
    }
  }

  enter() {
    this.setState({ isShow: true, animationType: "enter" })
  }

  leave() {
    this.setState(IS_IE_9 ? { isShow: false } : { animationType: "leave" })
  }

  onKeyUp = event => {
    if (!this.props.closeOnEsc || event.keyCode !== 27) {
      return
    }

    this.props.onClose(event)
  }

  animationEnd = () => {
    const { animationType } = this.state

    if (animationType === "leave") {
      this.setState({ isShow: false })
    }
  }

  render() {
    const {
      closeMaskOnClick,
      onClose,
      customMaskStyles,
      showMask,
      duration,
      className,
      children
    } = this.props

    const { isShow, animationType } = this.state

    const Mask = showMask ? (
      <div
        className="modal-mask"
        style={customMaskStyles}
        onClick={closeMaskOnClick ? onClose : void 0}
      />
    ) : null

    const style = {
      display: isShow ? "" : "none",
      animationDuration: duration + "ms",
      WebkitAnimationDuration: duration + "ms"
    }

    return (
      <div
        style={style}
        className={cx("modal", `modal-fade-${animationType}`, className)}
        onAnimationEnd={this.animationEnd}
        tabIndex={-1}
        onKeyUp={this.onKeyUp}
      >
        {Mask}
        <Dialog {...this.props} animationType={animationType}>
          {children}
        </Dialog>
      </div>
    )
  }
}
