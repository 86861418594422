import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useAsync } from "react-use"
import { useHistory } from "react-router-dom"
import { Button } from "../components/Button"
import { Divider } from "./CameraPage"
import { useTranslation } from "react-i18next"
import axios from "axios"
import dayjs from "dayjs"
import { CancelIcon, CashIcon, ConfirmIcon, DownloadIcon } from "../assets"
import { numberFormat } from "../functions/format"
import { ConfirmAlert } from "../components/ConfirmAlert"
import { Alert } from "../components/Alert"
import { Order, PhotoOrder, Status } from "../interfaces"
import { LineLoading } from "../components/LineLoading"
import { ImageLoading } from "../components/ImageLoading"
import { CheckboxButton } from "../components/CheckboxButton"
import { Header2 } from "../components/Header2"
import { CancelData, CancelPopup } from "../components/CancelPopup"
import { Loading } from "../components/Loading"
import { useQueryString } from "../hooks/useQueryString"
import Select from "react-select"
import store from "../store"

const isObjectEmpty = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object

export const ConfirmPage = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const qs = useQueryString()

  const [order, setOrder] = useState<Order | null>(null)
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState<any>("")
  const [isCancel, setIsCancel] = useState(false)
  const [shouldConfirm, setShouldConfirm] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)
  const [needEdit, setNeedEdit] = useState(false)
  const [reason, setReason] = useState("")
  const [data, setData] = useState<PhotoOrder[]>([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [code, setCode] = useState(-1)
  const [timing, setTiming] = useState({ text: "30", value: 30 })

  useAsync(async () => {
    await i18n.changeLanguage("vi")
  }, [])

  useAsync(async () => {
    try {
      const resp = await axios
        .get<Order & any>(
          `${process.env.REACT_APP_MINI_APP_URL}/v1/order/detail`,
          {
            params: {
              order_id: qs.order_id,
              token: store.token
            }
          }
        )
        .then(resp => resp.data)

      if (isObjectEmpty(resp) || resp.code === 1100) {
        setError(t("text.order_doesnt_exist"))
        return
      }

      if (resp.code === 1101) {
        setError(t("text.order_was_accepted"))
        return
      }

      if (resp.code === 1102) {
        setError(t("text.order_was_cancelled"))
        return
      }

      if (resp.code) {
        setCode(resp.code)
        setError(resp.message)
        return
      }

      setOrder(resp)
      setTotalPrice(resp.path[1].cod ?? 0)

      if (resp?.metadata) {
        const data = resp.metadata.data
        setData(data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      if (e.response) {
        const respData = e.response.data
        if (respData.message) {
          setCode(respData.code)
          setError(respData.message)
        } else {
          setError(respData)
        }
        return
      }

      if (e.message === "Network Error") {
        setError(t("text.system_has_an_error"))
        return
      }

      setError(e.message)
    }
  }, [])

  const onCancel = useCallback(
    async ({ reason }: CancelData) => {
      try {
        setSubmitLoading(true)
        await axios.post(`${process.env.REACT_APP_MINI_APP_URL}/v1/order/cancel`, {
          order_id: qs.order_id,
          comment: reason,
          token: store.token
        })

        setSubmitLoading(false)
        setIsCancelled(true)
      } catch (e) {
        setSubmitLoading(false)
        setError(e.message)
      }
    },
    [qs.order_id]
  )

  const onDownload = useCallback(() => {
    if (!order) {
      return
    }

    const win = window.open(order.metadata.signed_url, "_blank")
    win?.focus()
  }, [order])

  const onConfirm = useCallback(async () => {
    try {
      setShouldConfirm(true)
      setSubmitLoading(true)
      setShouldConfirm(false)

      if (!order) {
        throw new Error(t("text.cant_find_order"))
      }

      onDownload()

      await axios.post(`${process.env.REACT_APP_MINI_APP_URL}/v1/order/confirm`, {
        order_id: qs.order_id,
        token: store.token,
        need_edit: needEdit,
        service_id: order.metadata.service_id,
        process_photo_time: timing.value - 5
      })

      setSubmitLoading(false)
      history.push({
        pathname: "status",
        state: {
          status: Status.Succeed
        }
      })
    } catch (e) {
      setSubmitLoading(false)
      setError(e.message)
    }
  }, [needEdit, t, history, order, qs.order_id, onDownload, timing])

  useEffect(() => {
    if (error) {
      history.push({
        pathname: "status",
        state: {
          status: code,
          message: error
        }
      })
    }
  }, [error, history, code])

  const instruction_url = useMemo(() => {
    if (order) {
      if (order.metadata.photo_type === "passport") {
        return "https://storage.googleapis.com/aha-photolab-document/huong_dan_xac_nhan_visa.pdf"
      }

      return "https://storage.googleapis.com/aha-photolab-document/huong_dan_xac_nhan_photo.pdf"
    }

    return null
  }, [order])

  const shouldDownload = useMemo(() => {
    if (order) {
      return ["IDLE", "ASSIGNING", "ACCEPTED"].includes(order.status)
    }

    return false
  }, [order])

  const totalPhotos = useMemo(() => data.reduce((acc, item) => acc + item.config.num, 0),[data])

  return (
    <div className={"container"}>
      <Header2 title={t("app.confirmation_page")} />

      <div className={"content"}>
        <div className={"content__left"}>
          {data.length > 0 ? (
            data.map((photo, index) => (
              <div className={"photo"} key={`photo-${index}`}>
                <img
                  src={photo.thumbnail}
                  style={{ width: photo.visa_type ? "337px" : "100%" }}
                  alt=""
                />
                <div className={"photo__details"}>
                  <span className={"photo__details__title"}>Total</span>
                  <span>{photo.config.num}</span>

                  {photo.config.country && (
                    <>
                      <span className={"photo__details__title"}>Visa Type</span>
                      <span>{photo.config.country}</span>
                    </>
                  )}
                  {photo.config.size && (
                    <>
                      <span className={"photo__details__title"}>Size</span>
                      <span>
                        {photo.config.size} {photo.config.unit}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <ImageLoading />
          )}
        </div>

        <div className={"content__right"}>
          <h3 className={"content__title"}>{t("text.confirm_this_order")}</h3>

          {!!order ? (
            <div className={"content__info"}>
              <span className={"content__info__title"}>
                {t("text.order_id")}
              </span>
              <span className={"content__info__text"}>#{order._id}</span>

              <span className={"content__info__title"}>
                {t("text.date_created")}
              </span>
              <span className={"content__info__text"}>
                {dayjs.unix(order.create_time).format("DD/MM/YYYY - hh:mm")}
              </span>

              <span className={"content__info__title"}>
                {t("text.total_photos")}
              </span>
              <span className={"content__info__text"}>{totalPhotos}</span>

              <span className={"content__info__title"}>
                {t("text.total_fee")}
              </span>
              <span className={"content__info__text"}>
                <CashIcon />{" "}
                <span className={"pricing"}>
                  đ{numberFormat.format(totalPrice)}
                </span>
              </span>

              <span className={"content__info__title"}>
                {t("text.photo_need_edit")}
              </span>
              <span className={"content__info__text"}>
                <CheckboxButton
                  checked={needEdit}
                  onChecked={() => setNeedEdit(!needEdit)}
                />
              </span>
            </div>
          ) : (
            <LineLoading />
          )}

          {instruction_url && (
            <div className={'view-more'}>
              <a
                rel="noopener noreferrer"
                target={"_blank"}
                className={"content__info__title"}
                href={instruction_url}
              >
                Bấm vào đây để xem hướng dẫn xác nhận đơn hàng
              </a>
            </div>
          )}

          <span className={"content__info__title"} style={{ marginTop: 16 }}>
            {t("text.select_printing_time")}
          </span>
          <span className={"content__info__warning"}>
            {t("text.select_printing_time_warning")}
          </span>
          <div className={"content__info__dropdown"}>
            <Select<any>
              isMulti={false}
              isDisabled={loading}
              isSearchable={true}
              isClearable={true}
              closeMenuOnSelect={true}
              value={timing}
              getOptionLabel={v => v.text + " phút"}
              getOptionValue={v => v.value}
              onChange={v => setTiming(v)}
              options={[
                { text: "10", value: 10 },
                { text: "20", value: 20 },
                { text: "30", value: 30 },
                { text: "40", value: 40 },
                { text: "40", value: 50 }
              ]}
            />
          </div>

          {shouldDownload && (
            <div className={"actions"}>
              <Button
                icon={<DownloadIcon />}
                disabled={loading}
                // width={"187px"}
                color={"#142246"}
                background={"#f2f2f2"}
                title={t("button.download_photo")}
                onClick={onDownload}
              />
            </div>
          )}
          <br />
          {order?.status === "CONFIRMING" && (
            <div className={"actions"}>
              <Button
                icon={<ConfirmIcon />}
                disabled={loading}
                // width={"187px"}
                color={"#FFF"}
                background={"#FF8200"}
                title={t("button.confirm")}
                onClick={() => {
                  setShouldConfirm(true)
                }}
              />
              <Divider width={15} />
              <Button
                icon={<CancelIcon />}
                disabled={loading}
                // width={"187px"}
                background={"rgba(255, 59, 48, 0.1)"}
                color={"#FF3B30"}
                title={t("button.cancel")}
                onClick={() => {
                  setIsCancel(true)
                }}
              />
            </div>
          )}
        </div>
      </div>

      <CancelPopup
        visible={isCancel}
        onClose={() => setIsCancel(false)}
        onSubmit={async data => {
          setIsCancel(false)
          setReason(data.reason)
          await onCancel(data)
        }}
      />

      <Loading visible={submitLoading} />

      <ConfirmAlert
        visible={shouldConfirm}
        title={t("text.confirm_your_order")}
        message={t("text.pls_click_yes_to_confirm_order")}
        onClose={() => {
          setShouldConfirm(false)
        }}
        onConfirm={onConfirm}
      />

      <Alert
        visible={isCancelled}
        title={t("text.your_order_has_been_cancelled")}
        message={reason}
        buttonTitle={t("button.i_got_it")}
        onClick={() => {
          setIsCancelled(false)

          history.push({
            pathname: "status",
            state: {
              status: Status.Rejected
            }
          })
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          background #F4F5F7
          display flex
          justify-content center
          min-height 100vh
        }
        
        .view-more {
          display flex
          margin 16px 0
          
          a {
            color #ff3c2f
            text-transform uppercase
            text-decoration underline
          }
        }
        
        .photo {
          img {
            width 100%
          }
          
          &__details {
            display grid
            grid-template-columns 80px 1fr
            grid-row-gap 8px
            padding 16px
            background #F2F2F2
            margin-bottom 16px
            font-size 16px
            
            &__title {
              font-weight 500
            }
          }
        }

        .content {
          display flex
          align-items flex-start
          background #FFF
          margin 97px 60px 60px
          //height 620px
          padding 60px
          width fit-content
          height fit-content
          
          @media only screen and (max-width 830px) {
            flex-direction column
            align-items center
            width 100%
            padding 16px
          }
          
          &__left {
            //img {
            //  height 449px
            //  width 337px
            //  margin-bottom 16px
            //  object-fit contain
            //}
          }
          
          &__right {
            height 100%
            margin-left 60px
            
            @media only screen and (max-width 830px) {
              margin-top 32px
              margin-left 0
              width 100%
            }
          }
          
          &__info { 
            display grid
            grid-template-columns: 200px 200px
            grid-template-rows repeat(6, 44px)
            
            @media only screen and (max-width 830px) {
              grid-template-columns: 1fr 1fr
            }
            
            &__dropdown {
              margin-top 16px
            }
            
            &__warning {
              line-height: 20px;
              padding: 16px;
              display: block;
              background: #ffecea;
              color: #ff3c2f;
              margin: 16px 0;
              border-radius: 4px;
            }
            
            &__title {
              font-weight 500
              display flex
              align-items center
            }
            
            &__text {
              display flex
              align-items center
              
              .pricing {
                color #FF8200
                display inline-block
                margin-left 8px
              }
            }
          }
          
          &__title {
            font-size 24px
            font-weight 500
            line-height 36px
          }
          
          &__subtitle {
            display inline-block
            font-size 12px
            line-height 20px
            color #7A8189
            margin-bottom 21px
          }
        }
        
        .preview {
          width 200px
          //padding 8px
          background #EAF0FF
        }

        .actions {
          display flex
          margin-top 20px
        }
      `}</style>
    </div>
  )
}
