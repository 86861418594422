import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Modal } from "./Modal"
import { CloseIcon } from "../assets"
import { RadioButton } from "./RadioButton"
import { Button } from "./Button"
import { useTranslation } from "react-i18next"

export interface CancelData {
  reason: string
}

export type CancelPopupProps = {
  visible: boolean
  onClose(): void
  onSubmit(data: CancelData): void
}

export const CancelPopup: React.FC<CancelPopupProps> = ({
  visible,
  onClose,
  onSubmit
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const { t } = useTranslation()

  const [message, setMessage] = useState("")
  const [reasonIndex, setReasonIndex] = useState(0)

  const reasons = useMemo(() => {
    return [
      t("cancel_reason.doesnt_have_enough_time"),
      t("cancel_reason.photo_is_not_clear"),
      t("cancel_reason.posture_inappropriate"),
      t("cancel_reason.the_clothes_are_not_suitable"),
      t("cancel_reason.smile_when_talking_photo"),
      t("cancel_reason.other_reason")
    ]
  }, [t])
  const isOtherReason = useMemo(() => reasonIndex === reasons.length - 1, [
    reasonIndex,
    reasons
  ])

  const onChecked = useCallback(index => {
    setReasonIndex(index)
  }, [])

  useEffect(() => {
    if (isOtherReason && textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [textareaRef, isOtherReason])

  return (
    <Modal visible={visible}>
      <div className={"container"}>
        <div className={"header"}>
          <h3 className={"title"}>{t("text.reason_for_cancellation")}</h3>
          <div style={{ cursor: "pointer" }} onClick={onClose}>
            <CloseIcon fill={"#7A8189"} />
          </div>
        </div>
        <span className={"subtitle"}>
          {t("text.pls_let_us_know_the_reason")}
        </span>

        {reasons.map((r, index) => (
          <RadioButton
            name={"reason"}
            title={r}
            key={r}
            checked={reasonIndex === index}
            onChange={() => onChecked(index)}
          />
        ))}

        <textarea
          ref={textareaRef}
          className={"textarea"}
          disabled={!isOtherReason}
          rows={5}
          placeholder={t("text.leave_a_reason")}
          onChange={e => setMessage(e.target.value)}
          value={message}
        />

        <Button
          title={t("button.submit")}
          onClick={() => {
            onSubmit({
              reason: isOtherReason ? message : reasons[reasonIndex]
            })
          }}
        />

        {/*language=Stylus*/}
        <style jsx>{`
          .container {
            background #FFF
            padding 16px
            border-radius 4px
            width 520px
            
            @media only screen and (max-width 830px) {
              width 100%
            }
          }

          .header {
            display flex
            justify-content space-between
            align-items center
          }

          .title {
            font-size 16px
            line-height 24px
            font-weight 500
          }

          .subtitle {
            display inline-block
            font-size 14px
            line-height 20px
            color #7A8189
            margin-bottom 16px
          }

          .textarea {
            width 100%
            font-family "Roboto", sans-serif
            resize none
            padding 8px
            background #F2F2F2
            border none
            border-radius 4px
            margin-top 8px
            margin-bottom 16px
            
            &:focus {
              outline 2px solid #FF8200
              -moz-outline-radius 4px
              -webkit-outline-radius 4px
            }
          }
        `}</style>
      </div>
    </Modal>
  )
}
