import React, { useEffect, useRef, useState } from "react"
import { Button } from "./Button"
import { Divider } from "../pages/CameraPage"
import { TextInput } from "./TextInput"
import { useTranslation } from "react-i18next"
import { Modal } from "./Modal"

export type ConfirmAlertProps = {
  showInput?: boolean
  visible: boolean
  upperCase?: boolean
  title: string
  message: string
  value?: string
  buttonTitle?: string
  placeholder?: string
  onClose(): void
  onConfirm(value: string): void
}

export const ConfirmAlert: React.FC<ConfirmAlertProps> = ({
  showInput,
  visible,
  title,
  message,
  value,
  onClose,
  onConfirm,
  placeholder,
  upperCase
}) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLInputElement | null>(null)

  const [current, setCurrent] = useState(value!)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ref.current && visible) {
        ref.current.focus()
      }
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [ref, visible])

  return (
    <Modal visible={visible}>
      <div className={"content"}>
        <h3 className={"content__title"}>{title}</h3>
        <span className={"content__message"}>{message}</span>

        {showInput && (
          <form
            onSubmit={e => {
              e.preventDefault()
              onConfirm(current)
            }}
          >
            <TextInput
              ref={ref}
              value={upperCase ? current.toUpperCase() : current}
              box={true}
              autoFocus
              placeholder={placeholder}
              onChange={e => setCurrent(e.target.value)}
            />
          </form>
        )}

        <div className={"footer"}>
          <Button
            background={"#F2F2F2"}
            color={"#142246"}
            title={t("button.close")}
            onClick={onClose}
          />
          <Divider width={16} />
          <Button
            title={t("button.confirm")}
            onClick={() => onConfirm(current)}
          />
        </div>
      </div>

      {/*language=Stylus*/}
      <style jsx>{`
        .content {
          padding 16px
          background #FFF
          animation popup 0.7s
          border-radius 4px
          width 400px
          
          @media only screen and (max-width 830px) {
            width 100%
          }
          
          &__title {
            font-weight 500
            font-size 16px
            line-height 20px
            text-align center
            display block
            margin-bottom 12px
          }
          
          &__message {
            line-height 20px
            text-align center
            display inline-block
            color rgba(0, 0, 0, 0.65)
            margin-bottom 16px
            width 100%
          }
        }
        
        .footer {
          display flex
        }
      `}</style>
    </Modal>
  )
}

ConfirmAlert.defaultProps = {
  value: "",
  showInput: false
}
