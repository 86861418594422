import { useMemo } from "react"
import qs from "query-string"
import { useLocation } from "react-router-dom"

export const useQueryString = <T = any>(): T => {
  const location = useLocation()

  const parsedQuery: any = useMemo(() => qs.parse(location.search ?? ""), [location.search])

  return parsedQuery
}
