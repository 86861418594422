import React from "react"

export const ImageLoading = () => {
  return (
    <div className="container">
      <div className="image"/>

      {/*language=Stylus*/}
      <style jsx>{`
        $base-color = #ddd
        $shine-color = #e8e8e8
        $animation-duration = 1.6s
        $avatar-offset =  0 + 16
        
        background-gradient() {
          background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px) 
          background-size: 600px
        }
        
        .container {
          margin-bottom 16px
        }
          
        .image {
          width: 337px
          height: 449px
          margin-top: 12px
          
          background-gradient()
          animation: shine-avatar $animation-duration infinite linear
        }
        
        @keyframes shine-avatar {
          0% {
            background-position: -100px + $avatar-offset
          }
          40%, 100% {
            background-position: 337px + $avatar-offset
          }
        }

      `}</style>
    </div>
  )
}
