import React, { DetailedHTMLProps, InputHTMLAttributes } from "react"

export type TextInputProps = {
  box?: boolean
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ title, box, ...rest }, ref) => {
    return (
      <div className={"container"}>
        {!!title && <label className={"title"}>{title}</label>}
        <input ref={ref} className={"input"} type="text" {...rest} />

        {/*language=Stylus*/}
        <style jsx>{`
        .container {
          display flex
          flex-direction column
          margin-bottom 20px
        }
        
        .title {
          margin-bottom 4px
          color ${box ? "#BCBCBC" : "#1A1A1A"}
        }
        
        .input {
          font-size ${box ? 14 : 16}px
          line-height 24px
          outline none
          border ${box ? "none" : "1px solid #BCBCBC"}
          border-radius 3px
          height 38px
          padding 8px
          transition all 0.3s ease-in-out
          background ${box ? "#F6F6F6" : "#fff"}
          
          &:focus {
            border-color #FF8200
          }
        }
      `}</style>
      </div>
    )
  }
)
