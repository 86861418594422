import React from "react"

export type CircularButtonProps = {
  size: number
  icon: JSX.Element
  background?: string
  disabled?: boolean

  onClick?(): void
}

export const CircularButton: React.FC<CircularButtonProps> = ({
  size,
  icon,
  background,
  disabled,
  onClick
}) => {
  return (
    <button disabled={disabled} onClick={onClick}>
      {icon}

      {/*language=Stylus*/}
      <style jsx>{`
        button {
          height ${size}px
          width ${size}px
          background ${disabled ? "#BCBCBC" : background}
          border-radius 50%
          border none
          outline none
          padding 0
        }
      `}</style>
    </button>
  )
}

CircularButton.defaultProps = {
  background: "#FF8200"
}
