import { CircularButton } from "./CircularButton"
import { MinusIcon, PlusIcon } from "../assets"
import React from "react"

export type NumberInputProps = {
  onChange(value: number): void
  value: number
  min?: number
  loading?: boolean
  disabled?: boolean
  step?: number
}

export const NumberInput: React.FC<NumberInputProps> = ({
  step = 1,
  onChange,
  value,
  disabled,
  min
}) => {
  return (
    <div className={"container"}>
      <CircularButton
        icon={<MinusIcon />}
        size={32}
        disabled={disabled}
        // background={"#BCBCBC"}
        onClick={() => onChange(value === min ? min : value - step)}
      />
      <span className={"input"}>{value}</span>
      <CircularButton
        disabled={disabled}
        icon={<PlusIcon />}
        size={32}
        onClick={() => onChange(value + step)}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        button {
          > svg {
            margin-left - 8px
          }
        }
      `}</style>

      {/*language=Stylus*/}
      <style jsx>{`        
        .container {
          display flex
        }
        
        .input {
          width 42px
          height 32px
          margin 0 4px
          font-size 14px
          border 1px solid #BCBCBC
          border-radius 3px
          display flex
          justify-content center
          align-items center
        }
      `}</style>
    </div>
  )
}
