import { colors } from "../variables"
import React, { CSSProperties } from "react"
import Ripples from "react-ripples"

const absoluteStyle: CSSProperties = {
  position: "fixed",
  bottom: 16,
  left: 16,
  right: 16
}

export type ButtonProps = {
  title: string
  download?: boolean
  width?: string
  loading?: boolean
  background?: string
  color?: string
  absolute?: boolean
  disabled?: boolean
  padding?: number | string
  icon?: JSX.Element
  customStyles?: CSSProperties

  onClick?(): void
}

export const Button: React.FC<ButtonProps> = ({
  icon,
  width,
  title,
  disabled,
  background,
  color,
  absolute,
  padding,
  onClick,
  customStyles
}) => {
  return (
    <div className={"fixed"} style={{ width, ...customStyles }}>
      <div style={absolute ? absoluteStyle : { width, padding }}>
        <Ripples>
          <button
            onClick={disabled ? () => {} : onClick}
            style={{ backgroundColor: disabled ? "#BCBCBC" : background }}
          >
            {!!icon && icon}
            <span>{title}</span>
          </button>

        </Ripples>
      </div>

      {/*language=Stylus*/}
      <style jsx>{`
        .button {
          width 100%
          cursor pointer
        }
        
        .fixed {
          //transform translate3d(0,0,0)
          cursor pointer
        }
        
        button {
          border none
          width 100%
          height 50px
          border-radius 2px
          outline none
          display flex
          justify-content center
          align-items center
          cursor pointer
          
          span {
            margin-left 8px
            font-weight 400
            font-size 14px
            line-height 20px
            color ${color}
          }
        }
      `}</style>
    </div>
  )
}

Button.defaultProps = {
  background: colors.accent,
  color: "#fff",
  padding: 0,
  width: "100%"
}
