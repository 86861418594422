import React, { useState } from "react"
import { Header } from "../components/Header"
import { Process } from "../components/Process"
import { Loading } from "../components/Loading"
import store from "../store"

export const TrackingPage = () => {
  const [loading, setLoading] = useState(true)

  return (
    <div className={"container"}>
      <Header />
      <Process step={2} />

      {loading && <Loading visible={true} />}
      <iframe
        title={"Tracking Page"}
        className={"tracking-page"}
        src={store.order.shared_link}
        frameBorder="0"
        onLoad={e => {
          setLoading(false)
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .tracking-page {
          width 100%
          height calc(100vh - 98px)
        }
      `}</style>
    </div>
  )
}
