import React from "react"

export type SectionProps = {
  title: string
  content: string
}

const InstructionSection: React.FC<SectionProps> = ({ title, content }) => {
  return (
    <div className={"container"}>
      <strong className={"title"}>{title}</strong>
      <span className={"content"}>{content}</span>

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          padding 16px 16px 0
        }
        
        .title {
          display block
          font-size 16px
          font-weight 500
          line-height 22px
          margin-bottom 8px
          color #FF8200
        }
        
        .content {
          display inline-block
          font-size 14px
          line-height 20px
        }
      `}</style>
    </div>
  )
}

export default InstructionSection