import React from "react"

export type CaptureButtonProps = {
  onClick(e: any): void
}

export const CaptureButton: React.FC<CaptureButtonProps> = ({ onClick }) => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <circle cx="43" cy="43" r="43" fill="#FF8200" />
      <path
        d="M59 36V54C59 55.6562 57.6562 57 56 57H30C28.3438 57 27 55.6562 27 54V36C27 34.3438 28.3438 33 30 33H35.5L36.2688 30.9437C36.7063 29.775 37.825 29 39.075 29H46.9188C48.1688 29 49.2875 29.775 49.725 30.9437L50.5 33H56C57.6562 33 59 34.3438 59 36ZM50.5 45C50.5 40.8625 47.1375 37.5 43 37.5C38.8625 37.5 35.5 40.8625 35.5 45C35.5 49.1375 38.8625 52.5 43 52.5C47.1375 52.5 50.5 49.1375 50.5 45ZM48.5 45C48.5 48.0312 46.0312 50.5 43 50.5C39.9688 50.5 37.5 48.0312 37.5 45C37.5 41.9688 39.9688 39.5 43 39.5C46.0312 39.5 48.5 41.9688 48.5 45Z"
        fill="white"
      />
    </svg>
  )
}
